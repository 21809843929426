const CAR_PLANT = 0;
const PRESS_SHOP = 1;
const NEXON_BIW = 2;
const PAINT_SHOP = 3;
const TCF_1 = 4;
const TCF_2 = 5;
const JLR = 6;
const POWERTRAIN = 7;
const NOVA_BIW = 8;
const ALTROZ_BIW = 9;
const HARRIER_SAFARI = 10;
const CANTEEN_J = 11;
const COMPRESSOR_HOUSE = 12;
const ETP_SHOP = 13;
const CANTEEN_K = 14;
const shopWiseMeters = {
  1: {},
  2: {},
  3: {},
  4: {},
  5: {},
  6: {},
  7: {},
  8: {},
  9: {},
  10: {},
  11: {},
  12: {},
  13: {},
  14: {},
};
const METERS = "Meters";
const REPORT_HEADERS = "ReportHeaders";
const SHOP_NAME = "ShopName";
const TIME_STAMP = "TimeStamp";
const M1_METER_NAME = "Drinking Water Supply To JLR - (L-Block)";
const M2_METER_NAME = "Drinking Water Supply To Middlestack";
const M3_METER_NAME = "Main Process Water Supply";
const M4_METER_NAME = "MIDC To PH NO-04 Process";
const M5_METER_NAME = "MIDC To PH NO-04 Drinking";
const M6_METER_NAME = "Drinking Water Supply To J Block";
const M7_METER_NAME = "ETP | RO Reject";
const M8_METER_NAME = "ETP | ACF 2 Out";
const M9_METER_NAME = "ETP | JLR Garden";
const M10_METER_NAME = "ETP | RO Transfer";
const M11_METER_NAME = "ETP INLET";
const M12_METER_NAME = "ETP + STP OUTLET";
const M13_METER_NAME = "STP INLET";
const M14_METER_NAME = "STP OUTLET";
const M17_METER_NAME = "Solar K Grid Process";
const M26_METER_NAME = "EV Shop Drinking Water";
const M27_METER_NAME = "Engine Shop Drinking Water";
const M28_METER_NAME = "ERC Service Block Drinking";
const M29_METER_NAME = "OB Canteen MORI Drinking";
const M30_METER_NAME = "Office Block Drinking";
const M40_METER_NAME = "Engine Shop Process Water";
const M42_METER_NAME = "Omega PDI Drinking";
const M43_METER_NAME = "JLR Canteen Drinking";
const M46_METER_NAME = "Poly House/ National Yard Drinking";
const M63_METER_NAME = "Scanning Post Drinking Water";
const M49_METER_NAME = "Scrap Yard Drinking";
const M31_METER_NAME = "Solar System 01 Process";
const M45_METER_NAME = "Solar System 02 Process";
const M34_METER_NAME = "TCF 1A Process Water Line";
const M35_METER_NAME = "TCF 1B Process Water Line";
const M36_METER_NAME = "TCF 1C Process Water Line";
const M32_METER_NAME = "TCF 2 Drinking Water";
const M39_METER_NAME = "TCF 1 Drinking Water line ";
const M33_METER_NAME = "TCF 2 Process Water";
const M41_METER_NAME = "Compressor House Process";
const M56_METER_NAME = "Fire Hydrant PH-06";
const M57_METER_NAME = "RO Water To PH-06 Hydrant";
const M59_METER_NAME = "Compressor Room Process";
const M61_METER_NAME = "Press Shop Process";
const M23_METER_NAME = "Press Shop Drinking";
const M24_METER_NAME = "Weld Shop Drinking Water";
const M62_METER_NAME = "Weld Shop 'A' Grid Process";
const M15_METER_NAME = "Paint Shop 'O' Grid Process";
const M18_METER_NAME = "Paint Shop Canteen-01";
const M20_METER_NAME = "Paint Shop Canteen-02";
const M21_METER_NAME = "Paint Shop Drinking Water Line";
const M58_METER_NAME = "Paint Shop L Grid Process";
const M50_METER_NAME = "RO Rain Water Received From ETP";
const M51_METER_NAME =
  "Drinking Water supply To Office Block, Engineshop, PDI S Y";
const M54_METER_NAME = "RO Transfer Pump House-6";
const M55_METER_NAME = "OMEGA PDI CANTEEN";
const M37_METER_NAME = "JLR Washing";
const M38_METER_NAME = "TCF 1 Canteen MORI Drinking";
//Press shop configuration
shopWiseMeters[PRESS_SHOP][METERS] = ["M23", "M61"];

shopWiseMeters[PRESS_SHOP][REPORT_HEADERS] = [
  TIME_STAMP,
  M23_METER_NAME,
  M61_METER_NAME,
];

shopWiseMeters[PRESS_SHOP][SHOP_NAME] = "Press Shop";

//Nexon Biw configuration
shopWiseMeters[NEXON_BIW][METERS] = ["M17", "M24", "M62"];
shopWiseMeters[NEXON_BIW][REPORT_HEADERS] = [
  TIME_STAMP,
  M17_METER_NAME,
  M24_METER_NAME,
  M62_METER_NAME,
];
shopWiseMeters[NEXON_BIW][SHOP_NAME] = "Nexon Biw Shop";

//Paint shop configuration
shopWiseMeters[PAINT_SHOP][METERS] = ["M15", "M18", "M20", "M21", "M58"];

shopWiseMeters[PAINT_SHOP][REPORT_HEADERS] = [
  TIME_STAMP,
  M15_METER_NAME,
  M18_METER_NAME,
  M20_METER_NAME,
  M21_METER_NAME,
  M58_METER_NAME,
];
shopWiseMeters[PAINT_SHOP][SHOP_NAME] = "Paint Shop";

//TCF1 configuration
shopWiseMeters[TCF_1][METERS] = ["M34", "M35", "M36", "M32", "M39"];
shopWiseMeters[TCF_1][REPORT_HEADERS] = [
  TIME_STAMP,
  M34_METER_NAME,
  M35_METER_NAME,
  M36_METER_NAME,
  M32_METER_NAME,
  M39_METER_NAME,
];
shopWiseMeters[TCF_1][SHOP_NAME] = "TCF 1";

//TCF2 configuration
shopWiseMeters[TCF_2][METERS] = ["M32", "M33"];
shopWiseMeters[TCF_2][REPORT_HEADERS] = [
  TIME_STAMP,
  M32_METER_NAME,
  M33_METER_NAME,
];
shopWiseMeters[TCF_2][SHOP_NAME] = "TCF 2";

//JLR Configuration
shopWiseMeters[JLR][METERS] = ["M1"];
shopWiseMeters[JLR][REPORT_HEADERS] = [TIME_STAMP, M1_METER_NAME];
shopWiseMeters[JLR][SHOP_NAME] = "JLR";

//Powertrain configuration
shopWiseMeters[POWERTRAIN][METERS] = [
  "M27",
  "M42",
  "M63",
  "M49",
  "M40",
  "M31",
  "M45",
  "M37"
];
shopWiseMeters[POWERTRAIN][REPORT_HEADERS] = [
  TIME_STAMP,
  M27_METER_NAME,
  M42_METER_NAME,
  M63_METER_NAME,
  M49_METER_NAME,
  M40_METER_NAME,
  M31_METER_NAME,
  M45_METER_NAME,
  M37_METER_NAME
];
shopWiseMeters[POWERTRAIN][SHOP_NAME] = "Powertrain";

//Nova Biw block configuration
shopWiseMeters[NOVA_BIW][METERS] = ["M6"];
shopWiseMeters[NOVA_BIW][REPORT_HEADERS] = [TIME_STAMP, M6_METER_NAME];
shopWiseMeters[NOVA_BIW][SHOP_NAME] = "NOVA BIW";

//Altroz BIW block configuration
shopWiseMeters[ALTROZ_BIW][METERS] = ["M6"];
shopWiseMeters[ALTROZ_BIW][REPORT_HEADERS] = [TIME_STAMP, M6_METER_NAME];
shopWiseMeters[ALTROZ_BIW][SHOP_NAME] = "Altroz BIW";

//Nova Biw block configuration
shopWiseMeters[HARRIER_SAFARI][METERS] = ["M6"];
shopWiseMeters[HARRIER_SAFARI][REPORT_HEADERS] = [TIME_STAMP, M6_METER_NAME];
shopWiseMeters[HARRIER_SAFARI][SHOP_NAME] = "Harrier Safari";

//Canteen J configuration
shopWiseMeters[CANTEEN_J][METERS] = ["M6"];
shopWiseMeters[CANTEEN_J][REPORT_HEADERS] = [TIME_STAMP, M6_METER_NAME];
shopWiseMeters[CANTEEN_J][SHOP_NAME] = "Canteen J Block";

//Compressor House configuration
shopWiseMeters[COMPRESSOR_HOUSE][METERS] = ["M41", "M59"];
shopWiseMeters[COMPRESSOR_HOUSE][REPORT_HEADERS] = [
  TIME_STAMP,
  M41_METER_NAME,
  M59_METER_NAME,
];
shopWiseMeters[COMPRESSOR_HOUSE][SHOP_NAME] = "Compressor House";

//ETP Configuration
shopWiseMeters[ETP_SHOP][METERS] = [
  "M7",
  "M8",
  "M9",
  "M10",
  "M11",
  "M12",
  "M13",
  "M14",
  "M56",
  "M57",
];
shopWiseMeters[ETP_SHOP][REPORT_HEADERS] = [
  TIME_STAMP,
  M7_METER_NAME,
  M8_METER_NAME,
  M9_METER_NAME,
  M10_METER_NAME,
  M11_METER_NAME,
  M12_METER_NAME,
  M13_METER_NAME,
  M14_METER_NAME,
  M56_METER_NAME,
  M57_METER_NAME,
];
shopWiseMeters[ETP_SHOP][SHOP_NAME] = "ETP";

//CANTEEN K Configuration

shopWiseMeters[CANTEEN_K][METERS] = ["M18", "M20", "M29", "M55", "M38", "M43"];
shopWiseMeters[CANTEEN_K][REPORT_HEADERS] = [
  TIME_STAMP,
  M18_METER_NAME,
  M20_METER_NAME,
  M29_METER_NAME,
  M55_METER_NAME,
  M38_METER_NAME,
  M43_METER_NAME,
];
shopWiseMeters[CANTEEN_K][SHOP_NAME] = "Canteen K";

//Keys are dacpl meter numbers and values are TML meter numbers associated with keys
const TML_METER_NUMBERS = {
  M1: "8",
  M2: "6",
  M3: "5",
  M4: "3",
  M5: "4",
  M6: "9",
  M15: "17",
  M17: "22",
  M18: "20",
  M20: "21",
  M21: "19",
  M23: "25",
  M24: "24",
  M26: "27",
  M27: "33",
  M28: "31",
  M29: "32",
  M30: "30",
  M31: "40",
  M32: "12",
  M33: "11",
  M34: "13",
  M35: "14",
  M36: "15",
  M37: "42",
  M38: "44",
  M39: "16",
  M40: "34",
  M41: "29",
  M42: "38",
  M43: "46",
  M45: "41",
  M46: "37",
  M49: "35",
  M50: "10",
  M51: "7",
  M55: "36",
  M54: "",
  M56: "47",
  M57: "45",
  M58: "18",
  M59: "28",
  M61: "26",
  M62: "23",
  M63: "39",
};

module.exports = {
  CAR_PLANT,
  PRESS_SHOP,
  NEXON_BIW,
  PAINT_SHOP,
  TCF_1,
  TCF_2,
  JLR,
  POWERTRAIN,
  NOVA_BIW,
  ALTROZ_BIW,
  HARRIER_SAFARI,
  CANTEEN_J,
  COMPRESSOR_HOUSE,
  ETP_SHOP,
  CANTEEN_K,
  shopWiseMeters,
  M1_METER_NAME,
  M2_METER_NAME,
  M3_METER_NAME,
  M4_METER_NAME,
  M5_METER_NAME,
  M6_METER_NAME,
  M7_METER_NAME,
  M8_METER_NAME,
  M9_METER_NAME,
  M10_METER_NAME,
  M11_METER_NAME,
  M12_METER_NAME,
  M13_METER_NAME,
  M14_METER_NAME,
  M15_METER_NAME,
  M17_METER_NAME,
  M18_METER_NAME,
  M20_METER_NAME,
  M21_METER_NAME,
  M23_METER_NAME,
  M24_METER_NAME,
  M26_METER_NAME,
  M27_METER_NAME,
  M28_METER_NAME,
  M29_METER_NAME,
  M30_METER_NAME,
  M31_METER_NAME,
  M32_METER_NAME,
  M33_METER_NAME,
  M34_METER_NAME,
  M35_METER_NAME,
  M36_METER_NAME,
  M38_METER_NAME,
  M39_METER_NAME,
  M40_METER_NAME,
  M41_METER_NAME,
  M42_METER_NAME,
  M43_METER_NAME,
  M45_METER_NAME,
  M49_METER_NAME,
  M56_METER_NAME,
  M57_METER_NAME,
  M59_METER_NAME,
  M62_METER_NAME,
  M63_METER_NAME,
  M46_METER_NAME,
  M50_METER_NAME,
  M51_METER_NAME,
  M54_METER_NAME,
  M55_METER_NAME,
  M58_METER_NAME,
  M61_METER_NAME,
  M37_METER_NAME,
  TML_METER_NUMBERS,
};
