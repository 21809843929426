import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ReportTable } from "./ReportTable";
import "./styles/style.css";
import Meter from "./Components/Meter";
import VolumeChart from "./Components/VolumeChart";
import { Loader } from "../Loader/Loader";
import CardsDateFilter from "./Components/CardsDateFilter";
import ToastContext from "../Context/ToastContext";
import MultiAxisLineChart from "./Components/MultiAxisLineChart";
import { useAuth } from "../Context/AuthContext";
import { format, subDays, startOfDay, endOfDay } from "date-fns";
import {
  formatLargeRecordsDataTo10Values,
  formatLargeRecordsLabelsTo10Values,
  getCustomLabels,
  getCustomVolume,
  getDefaultVolume,
  getDefaultLabels,
  getAddition,
  getFormattedNumber,
} from "./utils/Lib";
import { ReportTable1 } from "./ReportTable1";
import MultiAxisLineChartETP from "./Components/MultiAxisLineChartETP";
import MultiaxisPowerTrain from "./Components/MultiaxisPowerTrain";
import { Link } from "react-router-dom";
import { TableReports } from "./TableReports";
import AxisTrendAndTabularReport from "./Components/AxisTrendAndTabularReport";
import {
  M27_METER_NAME,
  M31_METER_NAME,
  M37_METER_NAME,
  M40_METER_NAME,
  M42_METER_NAME,
  M45_METER_NAME,
  M49_METER_NAME,
  M63_METER_NAME,
  TML_METER_NUMBERS,
} from "./utils/Constants";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  reportTableCard: {
    background: "#F9F9F9",
    height: "475px",
    border: "none",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
  },
  multiAxisChartCard: {
    background: "#F9F9F9",
    height: "455px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  parentCard: {
    background: "#F9F9F9",
    height: "360px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  Volume: {
    background: "#375EAD",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "31.5px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
  totalVolDiv: {
    background: "#375EAD",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "12.5px",
  },
  reportTableCol: {
    padding: "20px",
    margin: "25px 0px",
  },
};

const PowerTrainReport = () => {
  const { token } = useAuth();
  const { toast } = useContext(ToastContext);
  const URL = process.env.REACT_APP_URL;
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [volChartData, setVolChartData] = useState([]);
  const [avgFlowRate, setAvgFlowRate] = useState(0.0);



  const handleCardDataChange = (data) => {
    setGraphData(data);
  };
  const handleLinechartDataChange = (data) => {
    setVolChartData(data);
  };
  const handleLoadingChange = (flag) => {
    setLoading(flag);
  };

  const fetchData = useCallback(async () => {
   handleLoadingChange(true)
   handleCardDataChange([]);
    handleLinechartDataChange([]);
    let url = `${URL}getShopwiseMetersData?shopNo=7&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      handleCardDataChange(res?.data?.flowRatesAndVolumeValues);
      handleLinechartDataChange(res?.data);
      res && handleLoadingChange(false) 
      // setTimeout(() => {
      //   setLoading(false);
      //   toast.success("Data updated");
      // }, 1000);
       graphData  && volChartData && toast.success("Data updated");;
    } catch (err) {}
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  // function to get avrage for m4fr and m5fr whole values from given date
  const fetchAvgFlowRate = useCallback(async () => {
    let url = `${URL}avgFr?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      setAvgFlowRate(response?.data?.avg.toFixed(2) || 0.0);
    
    } catch (err) {
      console.error(err.response.data?.message);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchAvgFlowRate();
  }, []);

  const setTrue =
    dateFilter.days.length ||
    dateFilter.startdate.length ||
    dateFilter.enddate.length;

  const getTotalDrinkingVolume = (m27Vol, m42Vol, m63Vol, m49Vol) => {
    const m27 = isNaN(Number(m27Vol)) ? 0 : Number(m27Vol);
    const m42 = isNaN(Number(m42Vol)) ? 0 : Number(m42Vol);
    const m63 = isNaN(Number(m63Vol)) ? 0 : Number(m63Vol);
    const m49 = isNaN(Number(m49Vol)) ? 0 : Number(m49Vol);
    const total = (m27 - m42 - m63 - m49).toFixed();
    return total;
  };

  const getTotalProcessVolume = (m40Vol, m31Vol, m45Vol , m37Vol) => {
    const m40 = isNaN(Number(m40Vol)) ? 0 : Number(m40Vol);
    const m31 = isNaN(Number(m31Vol)) ? 0 : Number(m31Vol);
    const m45 = isNaN(Number(m45Vol)) ? 0 : Number(m45Vol);
    const m37 = isNaN(Number(m37Vol)) ? 0 : Number(m37Vol);
    const total = (m40 - m31 - m45 - m37).toFixed();
    console.log("process total" , total)
    return total;
  };

  const getTotalDrinkingData = (m27Arr, m42Arr, m63Arr, m49Arr) => {
    const arrayLength = Math.max(
      m27Arr?.length,
      m42Arr?.length,
      m63Arr?.length,
      m49Arr?.length
    );
    while (m27Arr?.length < arrayLength) {
      m27Arr.push(m42Arr?.length > 0 ? m42Arr[m42Arr.length - 1] : 0);
    }

    while (m42Arr?.length < arrayLength) {
      m42Arr.push(m42Arr.length > 0 ? m42Arr[m42Arr.length - 1] : 0);
    }
    while (m63Arr?.length < arrayLength) {
      m63Arr.push(m63Arr.length > 0 ? m63Arr[m63Arr.length - 1] : 0);
    }

    while (m49Arr?.length < arrayLength) {
      m49Arr.push(m49Arr.length > 0 ? m49Arr[m49Arr.length - 1] : 0);
    }

    let combinedArray = [];
    for (let i = 0; i < arrayLength; i++) {
      const m27FormattedNumber = isNaN(Number(m27Arr[i]))
        ? 0
        : Number(m27Arr[i]);
      const m42FormattedNumber = isNaN(Number(m42Arr[i]))
        ? 0
        : Number(m42Arr[i]);
      const m63FormattedNumber = isNaN(Number(m63Arr[i]))
        ? 0
        : Number(m49Arr[i]);
      const m49FormattedNumber = isNaN(Number(m49Arr[i]))
        ? 0
        : Number(m63Arr[i]);
      const total = (
        m27FormattedNumber -
        m42FormattedNumber -
        m63FormattedNumber -
        m49FormattedNumber
      ).toFixed(2);
      combinedArray.push(total);
    }
    console.log("combinedArray", combinedArray);
    return combinedArray;
  };

  const getTotalProcessData = (m40Arr, m31Arr, m45Arr ,m37Arr) => {
    const arrayLength = Math.max(
      m40Arr?.length,
      m31Arr?.length,
      m45Arr?.length,
      m37Arr?.length
    );
    while (m40Arr?.length < arrayLength) {
      m40Arr.push(m40Arr?.length > 0 ? m40Arr[m40Arr.length - 1] : 0);
    }
    while (m31Arr?.length < arrayLength) {
      m31Arr.push(m31Arr.length > 0 ? m31Arr[m31Arr.length - 1] : 0);
    }
    while (m45Arr?.length < arrayLength) {
      m45Arr.push(m45Arr.length > 0 ? m45Arr[m45Arr.length - 1] : 0);
    }
    while (m37Arr?.length < arrayLength) {
      m45Arr.push(m37Arr.length > 0 ? m37Arr[m37Arr.length - 1] : 0);
    }

    let combinedArray = [];
    for (let i = 0; i < arrayLength; i++) {
      const m40FormattedNumber = isNaN(Number(m40Arr[i]))
        ? 0
        : Number(m40Arr[i]);
      const m31FormattedNumber = isNaN(Number(m31Arr[i]))
        ? 0
        : Number(m31Arr[i]);
      const m45FormattedNumber = isNaN(Number(m45Arr[i]))
        ? 0
        : Number(m45Arr[i]);
        const m37FormattedNumber = isNaN(Number(m37Arr[i]))
        ? 0
        : Number(m37Arr[i]);

      const total = (
        m40FormattedNumber -
        m31FormattedNumber -
        m45FormattedNumber -
        m37FormattedNumber
      ).toFixed(2);
      combinedArray.push(total);
    }
    console.log("combinedArray", combinedArray);
    return combinedArray;
  };

  const customDateData = [
    {
      title: "Total Drinking Water",
      flowRate: getFormattedNumber(graphData?.M27FR),
      volume: getTotalDrinkingVolume(
        getCustomVolume(graphData?.M27),
        getCustomVolume(graphData?.M42),
        getCustomVolume(graphData?.M63),
        getCustomVolume(graphData?.M49)
      ),
      labels: getCustomLabels(volChartData?.data?.M27),
      data: getTotalDrinkingData(
        volChartData?.data?.M27 &&
          formatLargeRecordsDataTo10Values(volChartData?.data?.M27),
        volChartData?.data?.M42 &&
          formatLargeRecordsDataTo10Values(volChartData?.data?.M42),
        volChartData?.data?.M63 &&
          formatLargeRecordsDataTo10Values(volChartData?.data?.M63),
        volChartData?.data?.M49 &&
          formatLargeRecordsDataTo10Values(volChartData?.data?.M49)
      ),
    },
    {
      title: "Total Process Water",
      flowRate: getFormattedNumber(graphData?.M40FR),
      volume: getTotalProcessVolume(
        getDefaultVolume(graphData?.M40),
        getDefaultVolume(graphData?.M31),
        getDefaultVolume(graphData?.M45),
        getDefaultVolume(graphData?.M37)
      ),
      labels: getCustomLabels(volChartData?.data?.M40),
      data: getTotalProcessData(
        volChartData?.data?.M40 &&
          formatLargeRecordsDataTo10Values(volChartData?.data?.M40),
        volChartData?.data?.M31 &&
          formatLargeRecordsDataTo10Values(volChartData?.data?.M31),
        volChartData?.data?.M45 &&
          formatLargeRecordsDataTo10Values(volChartData?.data?.M45),
          volChartData?.data?.M37 &&
          formatLargeRecordsDataTo10Values(volChartData?.data?.M37)
      ),
    },
    {
      meter: 27,
      title: `${TML_METER_NUMBERS.M27} : ${M27_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M27FR),
      volume: getCustomVolume(graphData?.M27),
      labels: getCustomLabels(volChartData?.data?.M27),
      data:
        volChartData?.data?.M27 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M27),
    },
    {
      meter: 40,
      title: `${TML_METER_NUMBERS.M40} : ${M40_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M40FR),
      volume: getCustomVolume(graphData?.M40),
      labels: getCustomLabels(volChartData?.data?.M40),
      data:
        volChartData?.data?.M40 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M40),
    },
    {
      meter: 42,
      title: `${TML_METER_NUMBERS.M42} : ${M42_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M42FR),
      volume: getCustomVolume(graphData?.M42),
      labels: getCustomLabels(volChartData?.data?.M42),
      data:
        volChartData?.data?.M42 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M42),
    }, //
    {
      meter: 45,
      title: `${TML_METER_NUMBERS.M45} : ${M45_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M45FR),
      volume: getCustomVolume(graphData?.M45),
      labels: getCustomLabels(volChartData?.data?.M45),
      data:
        volChartData?.data?.M45 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M45),
    },
    {
      meter: 63,
      title: `${TML_METER_NUMBERS.M63} : ${M63_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M27FR),
      volume: getCustomVolume(graphData?.M63),
      labels: getCustomLabels(volChartData?.data?.M63),
      data:
        volChartData?.data?.M63 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M63),
    },
    {
      meter: 31,
      title: `${TML_METER_NUMBERS.M31} : ${M31_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M31FR),
      volume: getCustomVolume(graphData?.M31),
      labels: getCustomLabels(volChartData?.data?.M31),
      data:
        volChartData?.data?.M31 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M31),
    },
    {
      meter: 49,
      title: `${TML_METER_NUMBERS.M49} : ${M49_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M49FR),
      volume: getCustomVolume(graphData?.M49),
      labels: getCustomLabels(volChartData?.data?.M49),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M49 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M49),
    },
    {
      meter: 37,
      title: `${TML_METER_NUMBERS.M37} : ${M37_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M37FR),
      volume: getCustomVolume(graphData?.M37),
      labels: getCustomLabels(volChartData?.data?.M37),
      data:
        volChartData?.data?.M37 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M37),
    },
  ];

  const defaultData = [
    {
      title: "Total Drinking Water",
      flowRate: getFormattedNumber(graphData?.M27FR),
      volume: getTotalDrinkingVolume(
        getDefaultVolume(graphData?.M27, volChartData?.data?.M27, "M27TV"),
        getDefaultVolume(graphData?.M42, volChartData?.data?.M42, "M42TV"),
        getDefaultVolume(graphData?.M63, volChartData?.data?.M63, "M63TV"),
        getDefaultVolume(graphData?.M49, volChartData?.data?.M49, "M40TV")
      ),
      labels: getDefaultLabels(volChartData?.data?.M27),
      data: getTotalDrinkingData(
        volChartData?.data?.M27.map((item) => item?.M27TV),
        volChartData?.data?.M42.map((item) => item?.M42TV),
        volChartData?.data?.M63.map((item) => item?.M63TV),
        volChartData?.data?.M49.map((item) => item?.M49TV)
      ),
    },
    {
      title: "Total Process Water",
      flowRate: getFormattedNumber(graphData?.M40FR),
      volume: getTotalProcessVolume(
        getDefaultVolume(graphData?.M40, volChartData?.data?.M40, "M40TV"),
        getDefaultVolume(graphData?.M31, volChartData?.data?.M31, "M31TV"),
        getDefaultVolume(graphData?.M45, volChartData?.data?.M45, "M45TV"),
        getDefaultVolume(graphData?.M37, volChartData?.data?.M37, "M37TV")
      ),
      labels: getDefaultLabels(volChartData?.data?.M40),
      data: getTotalProcessData(
        volChartData?.data?.M40.map((item) => item?.M40TV),
        volChartData?.data?.M31.map((item) => item?.M31TV),
        volChartData?.data?.M45.map((item) => item?.M45TV),
        volChartData?.data?.M37.map((item) => item?.M37TV)
      ),
    },
    {
      meter: 27,
      title: `${TML_METER_NUMBERS.M27} : ${M27_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M27FR),
      volume: getDefaultVolume(
        graphData?.M27,
        volChartData?.data?.M27,
        "M27TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M27),
      data: volChartData?.data?.M27.map((item) => item?.M27TV),
    },
    {
      meter: 40,
      title: `${TML_METER_NUMBERS.M40} : ${M40_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M40FR),
      volume: getDefaultVolume(
        graphData?.M40,
        volChartData?.data?.M40,
        "M40TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M40),
      data: volChartData?.data?.M40?.map((item) => item?.M40TV),
    },
    {
      meter: 42,
      title: `${TML_METER_NUMBERS.M42} : ${M42_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M42FR),
      volume: getDefaultVolume(
        graphData?.M42,
        volChartData?.data?.M42,
        "M42TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M42),
      data: volChartData?.data?.M42?.map((item) => item?.M42TV),
    },
    {
      meter: 31,
      title: `${TML_METER_NUMBERS.M31} : ${M31_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M31FR),
      volume: getDefaultVolume(
        graphData?.M31,
        volChartData?.data?.M31,
        "M31TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M31),
      data: volChartData?.data?.M31?.map((item) => item?.M31TV),
    },
    {
      meter: 63,
      title: `${TML_METER_NUMBERS.M63} : ${M63_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M63FR),
      volume: getDefaultVolume(
        graphData?.M63,
        volChartData?.data?.M63,
        "M63TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M63),
      data: volChartData?.data?.M63?.map((item) => item?.M63TV) || 0,
    },
    {
      meter: 45,
      title: `${TML_METER_NUMBERS.M45} : ${M45_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M45FR),
      volume: getDefaultVolume(
        graphData?.M45,
        volChartData?.data?.M45,
        "M45TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M45),
      data: volChartData?.data?.M45?.map((item) => item?.M45TV),
    },
    {
      meter: 49,
      title: `${TML_METER_NUMBERS.M49} : ${M49_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M49FR),
      volume: getDefaultVolume(
        graphData?.M49,
        volChartData?.data?.M49,
        "M49TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M49),
      data: volChartData?.data?.M49?.map((item) => item?.M49TV),
    },
    {
      meter: 37,
      title: `${TML_METER_NUMBERS.M37} : ${M37_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M37FR),
      volume: getDefaultVolume(
        graphData?.M37,
        volChartData?.data?.M37,
        "M37TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M37),
      data: volChartData?.data?.M37?.map((item) => item?.M37TV),
    },
  ];

  const currentDate = new Date();
  const startDay =
    dateFilter.startdate.length === 0
      ? dateFilter.days.length === 0
        ? subDays(currentDate, 1)
        : subDays(currentDate, parseInt(dateFilter.days) + 1)
      : subDays(new Date(dateFilter.startdate), 0);
  const endDay =
    dateFilter.enddate.length === 0
      ? subDays(currentDate, 1)
      : subDays(new Date(dateFilter.enddate), 0);
  const startTime = format(startOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");

  const Data = setTrue === 0 ? defaultData : customDateData;

  const array1 = Data[0]?.data;
  const array2 = Data[1]?.data;

  const maxLength = Math.max(array1?.length, array2?.length); // Update minLength

  while (array1?.length < maxLength) {
    array1.push(0);
  }

  while (array2?.length < maxLength) {
    array2.push(0);
  }

  const combinedArray = [];
  for (let i = 0; i < maxLength; i++) {
    const v1 = isNaN(Number(array1[i])) ? 0 : Number(array1[i]);
    const v2 = isNaN(Number(array2[i])) ? 0 : Number(array2[i]);
    const total = v1 + v2;
    combinedArray.push(total);
  }

  return (
    <div style={styles.MainDiv}>
     
        <>
          <Tabs />
          <Container fluid style={styles.container}>
          <Row>
              <Col xs={12} md={12} style={styles.column}>
                <div style={styles.timeStampDiv}>
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={styles.calenderIcon}
                  />
                  {(dateFilter.startdate === "" || dateFilter.enddate === "") &&
                  dateFilter.days.length === 0
                    ? ` ${startTime}`
                    : ` ${startTime} to ${endTime}`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <CardsDateFilter
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  fetchData={fetchData}
                  fetchAvgFlowRate={fetchAvgFlowRate}
                />
              </Col>
              <Col
                md={2}
                xl={2}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/shopbilling/Powertrain">
                  <Button
                    style={{
                      borderRadius: "2.667px",
                      border: "0.667px solid #375EAD",
                      backgroundColor: "white",
                      color: "#375EAD",
                      fontWeight: "bold",
                      width: "146px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      marginTop: "2.9%",
                      position: "absolute",
                      left: "76%",
                    }}
                  >
                    View Bill
                  </Button>
                </Link>
              </Col>
            </Row>
            {loading ? <Loader height={'30vh'}/> : (
              <>
           
            <Row>
              <Col
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                style={styles.column}
              >
                <div className="card" style={styles.parentCard}>
                  <>
                    <div style={styles.titleDiv}>
                      <div style={styles.title}>Total Consumption</div>
                      <div className="Box">
                        <div>
                          <Meter
                            value={getFormattedNumber(avgFlowRate)}
                            maxValue={
                              Math.abs(avgFlowRate) > 10000 ? 50000 : 20000
                            }
                            width={320}
                            height={200}
                          />
                          <div style={styles.flowRateDiv}>
                            {getFormattedNumber(avgFlowRate)}
                          </div>
                          <div style={styles.flowRateVol}>
                            Flow Rate (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            /h)
                          </div>
                        </div>
                        <div>
                          <VolumeChart
                            width={350}
                            height={220}
                            Labels={
                              setTrue === 0
                                ? ["00:00", "06:00", "12:00", "18:00", "23:59"]
                                : getCustomLabels(volChartData?.data?.M27)
                            }
                            Data={combinedArray}
                            suggestedMax={10000}
                          />
                          <div style={styles.totalVolDiv}>
                            {getAddition(Data[0]?.volume, Data[1]?.volume)}
                          </div>

                          <div style={styles.flowRateVol}>
                            Volume (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </Col>

              {Data?.map((item, index) => (
                <Col
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  style={styles.column}
                  key={index}
                >
                  <div className="card" style={styles.card}>
                    <>
                      <div style={styles.titleDiv}>
                        <div style={styles.title}>{item.title}</div>
                        <div className="Box">
                          <div>
                            <Link
                              to={
                                item?.meter
                                  ? `/live-dashboard/${item.meter}/${item.title}`
                                  : null
                              }
                            >
                              <Meter
                                value={item.flowRate}
                                maxValue={item.flowRate > 1000 ? 50000 : 10000}
                                width={280}
                                height={160}
                              />
                            </Link>
                            <div style={styles.FlowRate}>{item?.flowRate}</div>
                            <div style={styles.flowRateVol}>
                              Flow Rate (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              /h)
                            </div>
                          </div>
                          <div>
                            <VolumeChart
                              width={280}
                              height={160}
                              Labels={item.labels}
                              Data={item.data}
                            />
                            <div style={styles.Volume}>{item?.volume}</div>

                            <div style={styles.flowRateVol}>
                              Volume (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              )
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              ))}
            </Row>
            </>
            )}
             
            <AxisTrendAndTabularReport shopNo={7} token={token} />
          </Container>
        </>
     
    </div>
  );
};

export default PowerTrainReport;
