import React from "react";
import { Spinner } from "react-bootstrap";

export const Loader = (props) => {

  return (
    <>
      <div style={{ width: props?.width ? props?.width : '100vw', height: props?.height ? props?.height :'100vh',display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Spinner animation="border" variant="info" style={{height:'50px', width:'50px'}}/>
      </div>
    </>
  );
};
